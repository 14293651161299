import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store"

const routes = [
  {
    path: '/unsupported',
    name: 'unsupportedDevice',
    meta: {
      title: 'Uw apparaat wordt niet ondersteund'
    },
    component: () => import('../views/UnsupportedDeviceView.vue')
  },
  {
    path: '/',
    name: 'auth',
    component: () => import('../views/auth/AuthView.vue'),
    redirect: {
      name: 'login'
    },
    children: [
      {
        path: 'inloggen',
        name: 'login',
        meta: {
          title: 'Inloggen'
        },
        component: () => import('../views/auth/LoginView'),
      },
      {
        path: 'registreren',
        name: 'register',
        meta: {
          title: 'Registreren'
        },
        component: () => import('../views/auth/RegisterView')
      },
      {
        path: 'wachtwoord/vergeten',
        name: 'forgotPassword',
        meta: {
          title: 'Wachtwoord vergeten'
        },
        component: () => import('../views/auth/ForgotPasswordView')
      },
      {
        path: 'wachtwoord/vergeten/:token/bevestigen',
        name: 'confirmForgotPassword',
        meta: {
          title: 'Bevestig wachtwoord vergeten'
        },
        component: () => import('../views/auth/ConfirmForgotPasswordView')
      }
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthenticated']) {
        return next({
          name: 'home'
        })
      }

      next()
    }
  },
  {
    path: '/auth/bevestigen/:token',
    name: 'confirmLogin',
    meta: {
      title: 'E-mailadres bevestigen'
    },
    component: () => import('../views/auth/PostEmailConfirmationView'),
  },
  {
    path: '/uitnodiging/:token',
    name: 'patientInvitation',
    meta: {
      title: 'Uitnodiging aanvullen dossier'
    },
    component: () => import('../views/InvitationView.vue'),
  },
  {
    path: '/security',
    name: 'responsibleDisclosure',
    meta: {
      title: 'Responsible Disclosure'
    },
    component: () => import('../views/ResponsibleDisclosureView.vue'),
  },
  {
    path: '/homepagina',
    name: 'home',
    meta: {
      title: 'Homepagina',
      requiresAuth: true
    },
    component: () => import('../views/HomeView'),
  },
  {
    path: '/profiel',
    name: 'profile',
    meta: {
      title: 'Mijn profiel',
      requiresAuth: true
    },
    component: () => import('../views/ProfileView.vue'),
  },
  {
    path: '/gegevens/opvragen',
    name: 'dataTransfer',
    meta: {
      title: 'Mijn gegevens opvragen',
      requiresAuth: true
    },
    component: () => import('../views/DataTransferView.vue'),
  },
  {
    path: '/gegevens/verwijderen',
    name: 'deleteProfile',
    meta: {
      title: 'Mijn gegevens verwijderen',
      requiresAuth: true
    },
    component: () => import('../views/DeleteProfileView.vue'),
  },
  {
    path: '/bugs',
    name: 'createBugReport',
    meta: {
      title: 'Fouten opgeven',
      requiresAuth: true,
      requiresEmailConfirmation: true
    },
    component: () => import('@/views/admin/reports/CreateBugReportView.vue'),
  },
  {
    path: '/client/verzoeken',
    name: 'patientAgreementRequestsP',
    meta: {
      title: 'Digitale verzoeken tot cliëntdossiers',
      requiresAuth: true,
      requiresPatient: true,
      requiresEmailConfirmation: true
    },
    component: () => import('../views/healthcare/PatientAgreementRequestsView.vue'),
  },
  {
    path: '/client/dossier',
    name: 'myFile',
    meta: {
      title: 'Mijn dossier',
      requiresAuth: true,
      requiresPatient: true,
      requiresEmailConfirmation: true
    },
    component: () => import('../views/client/MyFileView.vue'),
  },
  {
    path: '/zorgverlener/clienten',
    name: 'patients',
    meta: {
      title: 'Mijn cliënten',
      requiresAuth: true,
      requiresHealthcare: true,
      requiresEmailConfirmation: true
    },
    component: () => import('../views/healthcare/PatientsView.vue'),
  },
  {
    path: '/zorgverlener/clienten/:id',
    name: 'viewPatient',
    meta: {
      title: 'Cliëntenndossier',
      requiresAuth: true,
      requiresHealthcare: true,
      requiresEmailConfirmation: true
    },
    component: () => import('../views/healthcare/PatientView.vue'),
  },
  {
    path: '/zorgverlener/metingen/:id',
    name: 'measure',
    meta: {
      title: 'Meting',
      requiresAuth: true,
      requiresHealthcare: true,
      requiresEmailConfirmation: true
    },
    component: () => import('../views/healthcare/MeasureView.vue'),
  },
  {
    path: '/zorgverlener/verzoeken',
    name: 'patientAgreementRequestsH',
    meta: {
      title: 'Digitale verzoeken tot cliëntendossiers',
      requiresAuth: true,
      requiresHealthcare: true,
      requiresEmailConfirmation: true
    },
    component: () => import('../views/healthcare/PatientAgreementRequestsView.vue'),
  },
  {
    path: '/admin/zorgverleners',
    name: 'healthcareProviders',
    meta: {
      title: 'Geregistreerde zorgverleners',
      requiresAuth: true,
      requiresAdmin: true,
      requiresSuperUser: true
    },
    component: () => import('../views/admin/HealthcareProvidersView.vue'),
  },
  {
    path: '/admin/zorgverleners/:id/bewerken',
    name: 'updateHealthcareProvider',
    meta: {
      title: 'Zorgverlener bewerken',
      requiresAuth: true,
      requiresAdmin: true,
      requiresSuperUser: true
    },
    component: () => import('../views/admin/UpdateHealthcareProviderView.vue'),
  },
  {
    path: '/admin/zorgverleners/verzoeken',
    name: 'healthcareValidations',
    meta: {
      title: 'Beoordeel toegang voor zorgverleners',
      requiresAuth: true,
      requiresAdmin: true,
      requiresSuperUser: true
    },
    component: () => import('../views/admin/HealthcareValidationsView.vue'),
  },
  {
    path: '/admin/keuzehulp/hoofdstukken',
    name: 'measurementChapters',
    meta: {
      title: 'Keuzehulp hoofdstukken',
      requiresAuth: true,
      requiresAdmin: true,
      requiresSuperUser: true
    },
    component: () => import('../views/admin/MeasurementChaptersView.vue'),
  },
  {
    path: '/admin/keuzehulp/hoofdstukken/:id/bewerken',
    name: 'updateMeasurementChapter',
    meta: {
      title: 'Keuzehulp hoofdstuk bewerken',
      requiresAuth: true,
      requiresAdmin: true,
      requiresSuperUser: true
    },
    component: () => import('../views/admin/UpdateMeasurementChapterView.vue'),
  },
  {
    path: '/admin/onderzoeksrapport',
    name: 'researchData',
    meta: {
      title: 'Onderzoeksrapport',
      requiresAuth: true,
      requiresAdmin: true,
      requiresSuperUser: true
    },
    component: () => import('../views/admin/ResearchDataView.vue'),
  },
  {
    path: '/admin/bugs',
    name: 'bugReports',
    meta: {
      title: 'Overzicht bugs',
      requiresAuth: true,
      requiresAdmin: true,
      requiresSuperUser: true
    },
    component: () => import('@/views/admin/reports/BugReportsView.vue'),
  },
  {
    path: '/beheerder/administrators',
    name: 'admins',
    meta: {
      title: 'Overzicht administrators',
      requiresAuth: true,
      requiresSuperUser: true
    },
    component: () => import('../views/superuser/AdminsView.vue'),
  },
  {
    path: '/beheerder/administrators/aanmaken',
    name: 'createAdmin',
    meta: {
      title: 'Administrator aanmaken',
      requiresAuth: true,
      requiresSuperUser: true
    },
    component: () => import('../views/superuser/CreateAdminView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `Fysieke zelfredzaamheid - ${to.meta.title}`;

  if (to.path === '/unsupported') {
    next();
    return;
  }

  // Check for unsupported path or screen width
  if (window.innerWidth < 1100) {
    next({ path: '/unsupported' });
    return;
  }

  // Check for authentication
  if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    console.log('firstcondition')
    next({ path: '/inloggen' });
    return;
  }

  // Check for email confirmation
  if (to.meta.requiresEmailConfirmation && !store.getters['auth/isEmailConfirmed']) {
    console.log('second condition')
    next({ path: '/homepagina' });
    return;
  }

  if ((to.meta.requiresAdmin && !store.getters['auth/isAdmin']) && (to.meta.requiresSuperUser && !store.getters['auth/isSuperUser'])) {
    console.log('third condition')
    next({ path: '/homepagina' });
    return;
  }

  // Check for healthcare role
  if (to.meta.requiresHealthcare && !store.getters['auth/isHealthcare']) {
    console.log('fourth condition')
    next({ path: '/homepagina' });
    return;
  }

  // Check for patient role
  if (to.meta.requiresPatient && !store.getters['auth/isPatient']) {
    console.log('fifth condition')
    next({ path: '/homepagina' });
    return;
  }

  // If none of the above conditions are met, allow the navigation
  next();
});

export default router
