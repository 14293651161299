<template>
  <div class="maintenance">
    <div class="card">
      <div class="card-header">
        <h1 class="card-title">We zijn in onderhoud</h1>
        <font-awesome-icon icon="fa-spinner" class="fa-pulse" />
      </div>
      <div class="card-body">
        <p>Beste gebruiker,</p>
        <p>Wij zijn momenteel bezig met een onderhoud aan onze website en zullen binnenkort weer volledig beschikbaar zijn. Onze excuses voor het ongemak.</p>
        <p>Wij doen ons best om de onderbreking zo kort mogelijk te houden en hopen dat u spoedig weer gebruik kunt maken van het dashboard.</p>
        <p>Met vriendelijke groet,</p>
        <p>Het lectoraat innovatie van beweegzorg</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintenanceView"
}
</script>

<style lang="scss" scoped>
.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .card {
    max-width: 700px;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-body p {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>