export const Gender = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
}

export function convertGender(gender) {
    switch (gender) {
        case Gender.Male:
            return 'Dhr.'
        case Gender.Female:
            return 'Mw.'
        default:
            return '';
    }
}

export function convertGenderEE(gender) {
    switch (gender) {
        case Gender.Male:
            return 'meneer'
        case Gender.Female:
            return 'mevrouw'
        default:
            return '';
    }
}

export function convertGenderE(gender) {
    switch (gender) {
        case Gender.Male:
            return 'Man'
        case Gender.Female:
            return 'Vrouw'
        default:
            return 'Geslacht niet opgegeven';
    }
}