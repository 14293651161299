<template>
  <div class="breadcrumb">
    <div class="nav-state">
      <font-awesome-icon icon="bars" class="fa-xl" :class="{ 'fa-rotate-90': collapsedBar }" @click.prevent="toggleCollapsedBar" />
      <h3>{{ title }}</h3>
    </div>
    <router-link :to="{ name: 'profile' }">
      <img v-if="user.avatar" :src="'data:' + user.avatar_type + ';base64,' + user.avatar" alt="Profiel foto" class="avatar avatar-sm">
      <img v-else src="@/assets/img/avatar.png" alt="Profiel foto" class="avatar avatar-sm">
    </router-link>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "BreadcrumbComponent",
  components: {FontAwesomeIcon},
  props: {
    title: String
  },
  data() {
    return {
      collapsedBar: false
    }
  },
  created() {
    let collapsedBar = localStorage.getItem('collapsedBar')

    this.collapsedBar = collapsedBar === 'true'
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    })
  },
  methods: {
    toggleCollapsedBar() {
      this.collapsedBar = !this.collapsedBar;
      localStorage.setItem('collapsedBar', this.collapsedBar);

      this.$root.$data.emitter.emit('collapsedBar', { isCollapsed: this.collapsedBar })
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff;
  border-bottom: 1px solid var(--divider-color);
  text-align: right;

  .nav-state {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    svg {
      cursor: pointer;
    }
  }

  .avatar-sm {
    width: 40px;
    height: 40px;
  }
}
</style>