<template>
  <div>
    <LoadingComponent v-if="loading" />
    <template v-else-if="status">
      <AlertsComponent />
      <div class="content" :class="{ 'collapsed-sidebar': collapsedBar }" v-if="isAuthenticated && (routeName !== 'patientInvitation' && routeName !== 'responsibleDisclosure' && routeName !== 'unsupportedDevice')">
        <SidebarMenu />
        <div class="view">
          <BreadcrumbComponent :title="title" />
          <div :class="{'container': routeName !== 'myFile'}">
            <router-view />
          </div>
        </div>
      </div>
      <router-view v-else-if="isAuthenticated === false || routeName === 'patientInvitation' || routeName === 'responsibleDisclosure' || routeName === 'unsupportedDevice'" />
    </template>
    <MaintenanceView v-else />
  </div>
</template>

<script>
import mitt from 'mitt'
import AlertsComponent from "@/components/alerts/AlertsComponent";
import {mapActions, mapGetters} from "vuex";
import SidebarMenu from "@/components/navigation/SidebarMenu";
import BreadcrumbComponent from "@/components/navigation/BreadcrumbComponent.vue";
import MaintenanceView from "@/views/MaintenanceView";
import HealthcheckService from "@/services/HealthcheckService";
import LoadingComponent from "@/components/LoadingComponent";

export default {
  components: {LoadingComponent, MaintenanceView, BreadcrumbComponent, SidebarMenu, AlertsComponent},
  data() {
    return {
      emitter: mitt(),
      title: '',
      status: false,
      loading: true,
      collapsedBar: false
    }
  },
  async created() {
    await this.performTest()
    setInterval(this.performTest, 10000)

    this.title = this.$route.meta.title
    this.collapsedBar = localStorage.getItem('collapsedBar') === 'true'
  },
  beforeMount() {
    if (this.$route.name !== 'unsupportedDevice' && window.innerWidth < 1100) {
      this.$router.push({name: 'unsupportedDevice'})
    }
  },
  mounted() {
    this.$root.$data.emitter.on('collapsedBar', (event) => {
      this.collapsedBar = event.isCollapsed
    })
  },
  watch: {
    '$route': function() {
      this.title = this.$route.meta.title;
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    }),
    routeName() {
      return this.$route.name
    },
  },
  methods: {
    ...mapActions({
      refreshUser: 'auth/refreshUser',
      signOut: 'auth/signOut'
    }),
    async performTest() {
      await HealthcheckService.getStatus().then((response) => {
        this.loading = false
        this.status = response.data.status === "UP"
      }).catch(() => {
        this.loading = false
        this.status = false
      })
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400&display=swap');
@import "assets/utilities.scss";

:root {
  --default-font-family: 'Poppins', sans-serif;
  --default-font-size: .9rem;

  --background-color: #EDF1FF;
  --background-hover: #f2f5f8;
  --background-hover-dark: #3a3b3d;

  --primary-color: #0887c8;
  --primary-color-light: rgba(4, 92, 173, 0.64);
  --primary-color-dark: #0556a1;
  --success-color: #07AE04;
  --danger-color: #AE1E04;
  --warning-color: #AE8F04;

  --navigation-color: #FFFFFF;
  --navigation-color-dark: #242527;
  --divider-color: #e9ebed;

  --twitter-color: #1da1f2;
  --youtube-color: #ff0000;
  --instagram-color: #8a3ab9;
  --snapchat-color: rgba(232, 229, 17, 0.96);
  --linkedin-color: #0072b1;

  --default-shadow: 0px 12px 50px rgb(125 125 125 / 10%);
  --md-shadow: 0px 12px 50px rgb(125 125 125 / 10%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--background-color);
  font-family: var(--default-font-family);
}

h1 {
  font-size: 2.9rem;
}

h2 {
  font-size: 1.9rem;
}

p, li, td, label, input[type="text"] {
  font-size: var(--default-font-size)
}
</style>