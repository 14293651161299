import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './store/subscriber'
import axios from 'axios'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faArrowRight,
    faDatabase,
    faPaperPlane,
    faCheck,
    faGears,
    faHouse,
    faMoon,
    faEnvelope,
    faFileArrowDown,
    faTrashArrowUp,
    faRepeat,
    faRightToBracket,
    faSignOut,
    faFolderOpen,
    faLockOpen,
    faLock,
    faFlag,
    faSquarePollVertical,
    faUser,
    faUsers,
    faUserPlus,
    faHospitalUser,
    faXmarkCircle,
    faToolbox,
    faFileArchive,
    faFileUpload,
    faXmark,
    faSpinner,
    faPlus,
    faArrowLeft,
    faEye,
    faBan,
    faCodePullRequest,
    faArrowRotateLeft,
    faRuler,
    faBookOpenReader,
    faFilePdf, faBars, faQuestion
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faDatabase, faPaperPlane, faFilePdf, faBars, faQuestion, faRightToBracket,faXmarkCircle, faFileArrowDown, faRepeat, faTrashArrowUp, faEnvelope, faSquarePollVertical, faFileUpload, faFolderOpen, faFileArchive, faFlag, faLockOpen, faToolbox, faBookOpenReader, faLock, faBan, faRuler, faCodePullRequest, faArrowRotateLeft, faSpinner, faUserPlus, faPlus, faArrowLeft, faHospitalUser, faCheck, faXmark, faArrowRight, faHouse, faGears, faSignOut, faMoon, faUser, faUsers, faEye)

axios.defaults.baseURL = process.env.VUE_APP_API_PATH

axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401 && router.currentRoute.value.name !== 'login') {
            store.dispatch('auth/signOut').then(() => location.reload())
        }
        return Promise.reject(error);
    }
);

store.dispatch('auth/attempt', { 'token': localStorage.getItem('token') })
    .then(() => {
        createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
    })
