import axios from "axios";

export default new class AuthService {
    login(email, password) {
        return axios.post('auth/login', {
            email: email,
            password: password
        })
    }

    register(email, password, bigNumber, initials, lastName, gender, dateOfBirth, profession, zipCodeWorkstation, WorkingExperience) {
        return axios.post('auth/register', {
            email: email,
            password: password,
            big_number: bigNumber,
            initials: initials,
            last_name: lastName,
            gender: gender,
            date_of_birth: dateOfBirth,
            profession: profession,
            zip_code_workstation: zipCodeWorkstation,
            working_experience: WorkingExperience
        })
    }

    resentEmailConfirmation() {
        return axios.post('auth/resent/email')
    }

    verifyEmail(token) {
        return axios.get( `auth/register/${token}/confirm`)
    }

    resetPasswordByEmail(email) {
        return axios.post('auth/reset/password', {
            email: email
        })
    }

    resetPassword(token, password) {
        return axios.patch(`auth/reset/password/${token}/confirm`, {
            password: password
        })
    }

    profile()
    {
        return axios.get('auth/profile')
    }
}