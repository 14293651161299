export const Role = {
    Patient: 'PATIENT',
    Healthcare: 'HEALTHCARE',
    HealthcarePendingConfirmation: 'HEALTHCARE_PENDING_CONFIRMATION',
    HealthcareBlocked: 'HEALTHCARE_BLOCKED',
    Admin: 'ADMIN',
    SuperUser: 'SUPERUSER',
    WaitingForRemoval: 'WAITING_FOR_REMOVAL'
}

export function convertRoleName(role) {
    switch (role) {
        case Role.SuperUser:
            return 'Beheerder'
        case Role.Admin:
            return 'Administrator';
        case Role.Healthcare:
            return 'Zorgverlener';
        case Role.HealthcarePendingConfirmation:
            return 'Niet geverifieerd'
        case Role.HealthcareBlocked:
            return 'Geblokkeerd'
        case Role.Patient:
            return 'Cliënt';
        default:
            return role;
    }
}