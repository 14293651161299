import axios from "axios";

export default new class UserService {
    updateProfile(password) {
        if (password && password.length === 0) {
            return
        }

        return axios.patch('user/profile', {
            password: password
        });
    }

    uploadAvatarImage(image) {
        const formData = new FormData();
        formData.append('image', image)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        return axios.post(`user/profile/avatar`, formData, config);
    }

    removalRequest() {
        return axios.post('user/profile/removal/submit')
    }

    revokeRemovalRequest() {
        return axios.post('user/profile/removal/revoke')
    }
}