<template>
  <div class="sidebar" :class="{ 'sidebar-dark' : darkMode, 'collapsed-sidebar': collapsedBar }">
    <div class="top-navigation">
      <div class="header">
        <img src="@/assets/img/icon.png" alt="Lectoraat Innovatie van Beweegzorg HU" class="logo">
        <div class="user">
          <h2 class="full-name">{{ fullName }}</h2>
          <h3 class="role">{{ role }}</h3>
        </div>
        <router-link :to="{ name: 'profile' }" class="profile" v-if="isTopLevel() || isEmailConfirmed">
          <font-awesome-icon icon="fa-user" />
        </router-link>
      </div>
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            <font-awesome-icon icon="fa-house" />
            <span>Homepagina</span>
          </router-link>
        </li>
        <template v-if="!isRemovalRequestDateSet && (isTopLevel() || isEmailConfirmed)">
          <li v-if="isPatient">
            <router-link :to="{ name: 'myFile' }">
              <font-awesome-icon icon="fa-file-archive" />
              <span>Mijn dossier</span>
            </router-link>
          </li>
          <li v-if="isPatient">
            <router-link :to="{ name: 'patientAgreementRequestsP' }">
              <font-awesome-icon icon="fa-code-pull-request" />
              <span>Verzoekencentrum</span>
            </router-link>
          </li>
          <li v-if="isHealthcare">
            <router-link :to="{ name: 'patients' }">
              <font-awesome-icon icon="fa-hospital-user" />
              <span>Mijn cliënten</span>
            </router-link>
          </li>
          <li v-if="isHealthcare">
            <router-link :to="{ name: 'patientAgreementRequestsH' }">
              <font-awesome-icon icon="fa-code-pull-request" />
              <span>Verzoekencentrum</span>
            </router-link>
          </li>
          <template v-if="isTopLevel()">
            <li>
              <router-link :to="{ name: 'healthcareProviders' }">
                <font-awesome-icon icon="fa-users" />
                <span>Zorgverleners</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'healthcareValidations' }">
                <font-awesome-icon icon="fa-check" />
                <span>Beoordeel zorgverleners</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'measurementChapters' }">
                <font-awesome-icon icon="fa-ruler" />
                <span>Hoofdstukken</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'researchData' }">
                <font-awesome-icon icon="fa-book-open-reader" />
                <span>Onderzoeksgegevens</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'bugReports' }">
                <font-awesome-icon icon="fa-flag" />
                <span>Rapportage bugs</span>
              </router-link>
            </li>
          </template>
          <li v-if="isSuperUser">
            <router-link :to="{ name: 'admins' }">
              <font-awesome-icon icon="fa-toolbox" />
              <span>Administrators</span>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
    <div class="bottom-navigation">
      <div class="divider"></div>
      <ul>
        <li v-if="isEmailConfirmed && (isPatient || isHealthcare)">
          <router-link :to="{ name: 'createBugReport' }">
            <font-awesome-icon icon="fa-flag" />
            <span>Fout melden</span>
          </router-link>
        </li>
        <li>
          <a href="#" @click.prevent="logout">
            <font-awesome-icon icon="fa-sign-out" />
            <span>Uitloggen</span>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="toggleDarkMode" :class="{ 'active': darkMode }">
            <font-awesome-icon icon="fa-moon" />
            <span>Donkere modus</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {convertRoleName} from "@/helpers/role";
import {convertGender} from "@/helpers/gender";

export default {
  name: "SidebarMenu",
  data() {
    return {
      darkMode: true,
      collapsedBar: false
    }
  },
  created() {
    this.collapsedBar = localStorage.getItem('collapsedBar') === 'true'

    if (localStorage.getItem('darkMode')) {
      this.darkMode = localStorage.getItem('darkMode') === 'true'
    }
  },
  mounted() {
    this.$root.$data.emitter.on('collapsedBar', (event) => {
      this.collapsedBar = event.isCollapsed
    })
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isEmailConfirmed: 'auth/isEmailConfirmed',
      isPatient: "auth/isPatient",
      isHealthcare: "auth/isHealthcare",
      isHealthcarePending: "auth/isHealthcarePending",
      isHealthcareBlocked: "auth/isHealthcareBlocked",
      isAdmin: "auth/isAdmin",
      isSuperUser: "auth/isSuperUser",
      isRemovalRequestDateSet: "auth/isRemovalRequestDateSet"
    }),
    ...mapActions({
      signOut: 'auth/signOut'
    }),
    role() {
      if (this.user == null) {
        return ''
      }
      return convertRoleName(this.user.roles[0])
    },
    gender() {
      if (this.user == null) {
        return ''
      }
      return convertGender(this.user.gender)
    },
    fullName() {
      if (this.isHealthcare || this.isHealthcarePending || this.isHealthcareBlocked) {
        return this.gender + ' ' + this.user.healthcare.last_name
      } else if (this.isPatient) {
        return this.gender + ' ' + this.user.patient.username
      } else if (this.isAdmin) {
        return 'Welkom admin'
      } else if (this.isSuperUser) {
        return 'Welkom beheerder'
      }

      return ''
    }
  },
  methods: {
    isTopLevel() {
      return this.isSuperUser || this.isAdmin
    },
    logout() {
      this.signOut
      this.$root.$data.emitter.emit('alerts', {
        type: 'alert-success',
        title: `U bent succesvol afgemeld`,
        description: `We hopen u snel weer te zien!`,
        delay: 3500
      })
      this.$router.push({ name: 'login' })
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      localStorage.setItem('darkMode', this.darkMode);
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--navigation-color);
  width: 300px;
  height: 100vh;
  border-right: 2px solid var(--divider-color);

  .header {
    display: grid;
    grid-template-columns: 40px auto 40px;
    column-gap: .75rem;
    align-items: center;
    padding: 1rem;

    .logo {
      height: 40px;
      width: 40px;
    }

    .user {
      display: flex;
      flex-direction: column;

      .full-name {
        font-size: 1rem;
      }

      .role {
        font-size: .8rem;
      }
    }

    .profile {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .75rem 1rem;
      border-radius: 8px;
      color: #000000;
      border: 1px solid var(--background-hover-dark);
    }

    .router-link-active {
      border: 1px solid transparent;
      background-color: var(--primary-color);
      color: #ffffff;
    }
  }

  .top-navigation {
    flex: 1;
    overflow-y: auto;

    ul {
      display: flex;
      flex-direction: column;

      li {
        margin: .5rem 0 !important;
      }
    }
  }

  .bottom-navigation {
    margin-top: auto;
  }

  .top-navigation ul, .bottom-navigation ul {
    list-style: none;
    padding: 0 1rem;

    li {
      margin: 1rem 0;

      .router-link-active {
        background-color: var(--primary-color);
        color: #ffffff;

        &:hover {
          border: 1px solid transparent;
        }
      }

      a {
        display: grid;
        grid-template-columns: 35px auto;
        align-items: center;
        color: #000000;
        text-decoration: none;
        padding: .75rem 1rem;
        border-radius: 8px;
        border: 1px solid transparent;
        transition: border 300ms ease-out;
        font-size: .9rem;

        &:hover {
          border: 1px solid var(--background-hover-dark);
        }
      }
    }
  }

  .bottom-navigation ul li {
    margin-bottom: 1rem;
  }
}

.collapsed-sidebar {
  width: 90px;

  .top-navigation, .bottom-navigation {
    .header {
      display: flex;
      justify-content: center;
      align-items: center;

      .user, .profile {
        display: none;
      }
    }

    li a {
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding: 1rem !important;

      span {
        display: none;
      }
    }
  }
}

.sidebar-dark {
  background-color: var(--navigation-color-dark);

  .header, .header .profile {
    color: #ffffff;

    .router-link-active {
      background-color: var(--background-hover-dark);
    }
  }

  .top-navigation ul li, .bottom-navigation ul li {
    a {
      color: #ffffff;
    }

    .router-link-active {
      background-color: var(--background-hover-dark);
    }

    .active {
      border: 1px solid var(--background-hover-dark);
    }
  }
}
</style>