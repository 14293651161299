import {Role} from "@/helpers/role";
import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
    },
    getters: {
        roles(state) {
            if (state.user == null) {
                return false
            }
            if (state.user.roles == null) {
                return false
            }

            return state.user.roles
        },
        isAuthenticated(state)
        {
            return state.token !== null && state.user !== null
        },
        isEmailConfirmed(state)
        {
            if (state.user == null) {
                return false
            }
            return state.user.is_email_confirmed
        },
        isRemovalRequestDateSet(state)
        {
            if (state.user == null) {
                return false
            }
            return state.user.is_removal_request_date_set
        },
        isWaitingForRemoval(state)
        {
            if (state.user == null) {
                return false
            }
            return state.user.roles.includes(Role.WaitingForRemoval.toUpperCase())
        },
        isSuperUser(state)
        {
            if (state.user == null) {
                return false
            }
            if (state.user.roles == null) {
                return false
            }
            return state.user.roles.includes(Role.SuperUser.toUpperCase())
        },
        isAdmin(state)
        {
            if (state.user == null) {
                return false
            }
            if (state.user.roles == null) {
                return false
            }
            return state.user.roles.includes(Role.Admin.toUpperCase())
        },
        isHealthcare(state)
        {
            if (state.user == null) {
                return false
            }
            if (state.user.roles == null) {
                return false
            }
            return state.user.roles.includes(Role.Healthcare.toUpperCase())
        },
        isHealthcarePending(state)
        {
            if (state.user == null) {
                return false
            }
            if (state.user.roles == null) {
                return false
            }
            return state.user.roles.includes(Role.HealthcarePendingConfirmation.toUpperCase())
        },
        isHealthcareBlocked(state)
        {
            if (state.user == null) {
                return false
            }
            if (state.user.roles == null) {
                return false
            }
            return state.user.roles.includes(Role.HealthcareBlocked.toUpperCase())
        },
        isPatient(state)
        {
            if (state.user == null) {
                return false
            }
            if (state.user.roles == null) {
                return false
            }
            return state.user.roles.includes(Role.Patient.toUpperCase())
        },
        getUser(state)
        {
            return state.user
        },
        getNotifications(state)
        {
            if (state.user == null) {
                return false
            }
            return state.user.notifications.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
        },
        getRemovalRequestDate(state)
        {
            if (state.user == null) {
                return false
            }
            return state.user.removal_request_date
        }
    },
    mutations: {
        setToken(state, token)
        {
            state.token = token
        },
        setUser(state, user)
        {
            state.user = user
        }
    },
    actions: {
        async authenticateUser({dispatch}, credentials)
        {
            const response = await AuthService.login(credentials.email, credentials.password)

            return dispatch('attempt', { token: response.data.jwt_token })
        },
        async attempt({commit, state}, object)
        {
            if (object.token) {
                commit('setToken', object.token)
            }

            if (!state.token) {
                return
            }

            return new Promise(((resolve, reject) => {
                AuthService.profile()
                    .then(response => {
                        commit('setUser', response.data)
                        resolve(response)
                    }).catch((error) => {
                        commit('setUser', null)
                        commit('setToken', null)
                        reject(error)
                    }
                )
            }))
        },
        async uploadUserAvatar({dispatch}, image)
        {
            return new Promise(((resolve, reject) => {
                UserService.uploadAvatarImage(image)
                    .then(() => {
                        dispatch('attempt', { token: localStorage.getItem('token') })
                            .then(response => resolve(response))
                            .catch(error => reject(error))
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }))
        },
        async refreshUser({dispatch})
        {
            return new Promise(((resolve, reject) => {
                dispatch('attempt', { token: localStorage.getItem('token') })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            }))
        },
        signOut({commit}) {
            commit('setToken', null)
            commit('setUser', null)
        },
    }
}